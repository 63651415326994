/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery-modal@0.9.2/jquery.modal.min.css
 * - /npm/animate.css@4.1.1/animate.min.css
 * - /npm/noty@3.2.0-beta/lib/noty.min.css
 * - /npm/noty@3.2.0-beta/lib/themes/mint.min.css
 * - /npm/swiper@10.3.1/swiper-bundle.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
